/**
 * Grid
 * --------------------------------------------------
 * Using flexbox for the grid, inspired by Philip Walton:
 * http://philipwalton.github.io/solved-by-flexbox/demos/grids/
 * By default each .col within a .row will evenly take up
 * available width, and the height of each .col with take
 * up the height of the tallest .col in the same .row.
 */
.flex-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px;
  width: 100%; }

.row-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.row-no-padding {
  padding: 0; }
  .row-no-padding > .flex-col {
    padding: 0; }

.flex-row + .flex-row {
  margin-top: -4px;
  padding-top: 0; }

.flex-col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: block;
  padding: 4px;
  width: 100%; }

.col-no-padding {
  padding: 0; }
  .col-no-padding > .flex-row {
    padding: 0; }

/* Vertically Align Columns */
/* .row-* vertically aligns every .col in the .row */
.row-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.row-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.row-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.row-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch; }

.row-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  align-items: baseline; }

/* .col-* vertically aligns an individual .col */
.col-top {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.col-bottom {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end; }

.col-center {
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

/* Column Offsets */
.col-offset-5 {
  margin-left: 5%; }

.col-offset-10 {
  margin-left: 10%; }

.col-offset-20 {
  margin-left: 20%; }

.col-offset-25 {
  margin-left: 25%; }

.col-offset-33, .col-offset-34 {
  margin-left: 33.3333%; }

.col-offset-50 {
  margin-left: 50%; }

.col-offset-66, .col-offset-67 {
  margin-left: 66.6666%; }

.col-offset-75 {
  margin-left: 75%; }

.col-offset-80 {
  margin-left: 80%; }

.col-offset-90 {
  margin-left: 90%; }

/* Explicit Column Percent Sizes */
/* By default each grid column will evenly distribute */
/* across the grid. However, you can specify individual */
/* columns to take up a certain size of the available area */
.col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 5%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 5%;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  max-width: 5%; }

.col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 10%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 10%;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
  max-width: 10%; }

.col-15 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 15%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 15%;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  max-width: 15%; }

.col-20 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%; }

.col-25 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-30 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%; }

.col-33, .col-34 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.3333%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 33.3333%;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  max-width: 33.3333%; }

.col-35 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 35%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 35%;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
  max-width: 35%; }

.col-40 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%; }

.col-45 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 45%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 45%;
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%; }

.col-50 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-55 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 55%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 55%;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  max-width: 55%; }

.col-60 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 60%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 60%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%; }

.col-65 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 65%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 65%;
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
  max-width: 65%; }

.col-66, .col-67 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.6666%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 66.6666%;
  -ms-flex: 0 0 66.6666%;
  flex: 0 0 66.6666%;
  max-width: 66.6666%; }

.col-70 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 70%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 70%;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%; }

.col-75 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-80 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 80%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 80%;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  max-width: 80%; }

.col-85 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 85%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 85%;
  -ms-flex: 0 0 85%;
  flex: 0 0 85%;
  max-width: 85%; }

.col-90 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 90%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 90%;
  -ms-flex: 0 0 90%;
  flex: 0 0 90%;
  max-width: 90%; }

.col-95 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 95%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 95%;
  -ms-flex: 0 0 95%;
  flex: 0 0 95%;
  max-width: 95%; }

.mt-1 {
  margin-top: 1rem !important; }

.mt-2 {
  margin-top: 2rem !important; }

.mt-3 {
  margin-top: 3rem !important; }

.mt-4 {
  margin-top: 4rem !important; }

.mr-1 {
  margin-right: 1rem !important; }

.mr-2 {
  margin-right: 2rem !important; }

.ml-1 {
  margin-left: 1rem !important; }

.ml-2 {
  margin-left: 2rem !important; }

.mb-1 {
  margin-bottom: 1rem !important; }

.mb-2 {
  margin-bottom: 2rem !important; }

/* Responsive Grid Classes */
/* Adding a class of responsive-X to a row */
/* will trigger the flex-direction to */
/* change to column and add some margin */
/* to any columns in the row for clearity */
@media (max-width: 567px) {
  .responsive-sm {
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .responsive-sm > .col,
    .responsive-sm > .col-10,
    .responsive-sm > .col-20,
    .responsive-sm > .col-25,
    .responsive-sm > .col-30
.responsive-sm > .col-33,
    .responsive-sm > .col-34,
    .responsive-sm > .col-40,
    .responsive-sm > .col-50,
    .responsive-sm > .col-60
.responsive-sm > .col-66,
    .responsive-sm > .col-67,
    .responsive-sm > .col-75,
    .responsive-sm > .col-80,
    .responsive-sm > .col-90 {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-left: 0;
      max-width: 100%;
      width: 100%; } }

@media (max-width: 768px) {
  .responsive-md {
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .responsive-md > .col,
    .responsive-md > .col-10,
    .responsive-md > .col-20,
    .responsive-md > .col-25,
    .responsive-md > .col-30
.responsive-md > .col-33,
    .responsive-md > .col-34,
    .responsive-md > .col-40,
    .responsive-md > .col-50,
    .responsive-md > .col-60
.responsive-md > .col-66,
    .responsive-md > .col-67,
    .responsive-md > .col-75,
    .responsive-md > .col-80,
    .responsive-md > .col-90 {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-left: 0;
      max-width: 100%;
      width: 100%; } }

@media (max-width: 1023px) {
  .responsive-lg {
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .responsive-lg > .col,
    .responsive-lg > .col-10,
    .responsive-lg > .col-20,
    .responsive-lg > .col-25,
    .responsive-lg > .col-30
.responsive-lg > .col-33,
    .responsive-lg > .col-34,
    .responsive-lg > .col-40,
    .responsive-lg > .col-50,
    .responsive-lg > .col-60
.responsive-lg > .col-66,
    .responsive-lg > .col-67,
    .responsive-lg > .col-75,
    .responsive-lg > .col-80,
    .responsive-lg > .col-90 {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-left: 0;
      max-width: 100%;
      width: 100%; } }

/*
 * Base structure
 */
/* Move down content because we have a fixed navbar that is 50px tall */
body {
  height: 100%;
  margin: 0; }

/*
 * Global add-ons
 */
.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee; }

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
  border: 0; }

/*
 * Sidebar
 */
/* Hide for mobile, show later */
.sidebar {
  display: none; }

@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    border-right: 1px solid #eee; } }

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px;
  /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px; }

.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px; }

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #428bca; }

/*
 * Main content
 */
.main {
  padding-top: 30px; }

@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px; } }

.main .page-header {
  margin-top: 0; }

/*
 * Placeholder dashboard ideas
 */
.placeholders {
  margin-bottom: 30px;
  text-align: center; }

.placeholders h4 {
  margin-bottom: 0; }

.placeholder {
  margin-bottom: 20px; }

.placeholder img {
  display: inline-block;
  border-radius: 50%; }

.navbar {
  border-radius: 0px !important; }

.font-chivo {
  font-family: "Chivo"; }

.font-italic {
  font-style: italic; }

.text-bold {
  font-weight: bold; }

.no-margin {
  margin: 0; }

.no-padding {
  padding: 0; }

.flex-container {
  display: flex !important;
  flex: 1; }
  .flex-container.column {
    flex-direction: column; }
  .flex-container.align {
    align-items: center; }
  .flex-container.justify {
    justify-content: center; }
  .flex-container.between {
    justify-content: space-between; }
  .flex-container.around {
    justify-content: space-around; }
  .flex-container.direction-row {
    flex-direction: row; }
  .flex-container.end {
    justify-content: flex-end; }
  .flex-container.start {
    justify-content: flex-start; }
  .flex-container.wrap {
    flex-wrap: wrap; }
  .flex-container.container-60 {
    width: 60%; }
  .flex-container .icon {
    font-size: 2.5em; }
  .flex-container i.success {
    font-size: 54px;
    color: #78b529; }
  .flex-container i.alert {
    font-size: 54px;
    color: #f9cc30; }
    .flex-container i.alert.no-padding {
      margin: 0 !important; }
  .flex-container i.big-icon {
    font-size: 54px; }
  .flex-container i.loading {
    font-size: 54px;
    animation: spining .5s;
    animation-iteration-count: infinite; }
    .flex-container i.loading.small {
      font-size: 1em; }

.inner-wrapper {
  min-height: calc(100vh - 120px); }

.menu-user {
  width: 230px;
  color: #fff; }

.header-menu-items a {
  color: #fff !important;
  margin: 0 1rem 0 1rem; }

.unstyled-list {
  list-style: none; }

.error-formulario {
  font-size: 10px;
  color: red; }

.footer-content {
  width: 80% !important;
  margin: 0 auto; }

.sidebar {
  background: #222 !important;
  color: #fff !important; }
  .sidebar a {
    color: #ffff; }

.nav-pills li.active a, .nav-pills li.active a:focus, .nav-pills li.active a:hover, .nav-pills li.active a:link, .nav-pills li.active a:active {
  background: #78b529;
  color: #000;
  font-weight: bold; }

.nav li a:hover {
  background: #78b529;
  color: #000;
  font-weight: bold; }

.social-button {
  width: 130px !important;
  margin: 5px 0; }

@media (max-width: 767px) {
  .menu-user {
    display: none !important; } }

.btn-pickwin {
  color: #fff;
  background-color: #78b529;
  border-color: #70a926; }
  .btn-pickwin:focus, .btn-pickwin.focus {
    color: #fff;
    background-color: #5c8b20;
    border-color: #2b410f; }
  .btn-pickwin:hover {
    color: #fff;
    background-color: #5c8b20;
    border-color: #4f771b; }
  .btn-pickwin:active, .btn-pickwin.active,
  .open > .dropdown-toggle .btn-pickwin {
    color: #fff;
    background-color: #5c8b20;
    background-image: none;
    border-color: #4f771b; }
    .btn-pickwin:active:hover, .btn-pickwin:active:focus, .btn-pickwin:active.focus, .btn-pickwin.active:hover, .btn-pickwin.active:focus, .btn-pickwin.active.focus,
    .open > .dropdown-toggle .btn-pickwin:hover,
    .open > .dropdown-toggle .btn-pickwin:focus,
    .open > .dropdown-toggle .btn-pickwin.focus {
      color: #fff;
      background-color: #496e19;
      border-color: #2b410f; }
  .btn-pickwin.disabled:hover, .btn-pickwin.disabled:focus, .btn-pickwin.disabled.focus, .btn-pickwin[disabled]:hover, .btn-pickwin[disabled]:focus, .btn-pickwin[disabled].focus,
  fieldset[disabled] .btn-pickwin:hover,
  fieldset[disabled] .btn-pickwin:focus,
  fieldset[disabled] .btn-pickwin.focus {
    background-color: #78b529;
    border-color: #70a926; }
  .btn-pickwin .badge {
    color: #78b529;
    background-color: #fff; }

.login-header {
  min-height: 90px;
  background: #011924; }
  .login-header img {
    width: auto;
    height: 33px;
    margin-left: 3rem; }

.login-form {
  display: flex;
  margin-right: 3rem;
  color: white; }
  .login-form .login-input {
    background: white;
    border-radius: 5px;
    text-align: center;
    margin: 0 1rem;
    height: 33px;
    width: 200px;
    color: black;
    font-size: 14px; }
  .login-form .login-button {
    margin: 0 1rem;
    width: 160px;
    height: 31px;
    font-size: 14px;
    border-radius: 0;
    margin-top: 1px; }
  .login-form a {
    color: white;
    text-decoration: none; }
  .login-form .login-error {
    color: orange;
    font-size: 10px;
    text-align: center;
    width: 80%; }

.login-information {
  width: 100%;
  height: auto;
  background: url(/images/login-information-background.png) no-repeat center center;
  color: white; }

.login-information-content {
  padding: 3rem 0;
  text-align: center;
  text-shadow: 2px 2px 2px black; }
  .login-information-content .title {
    color: #63af18;
    font-size: 60px;
    font-weight: bold;
    font-style: italic; }
  .login-information-content .subtitle {
    font-size: 32px;
    font-weight: bold;
    font-style: italic;
    margin-top: 1rem; }
  .login-information-content .information {
    font-size: 22px;
    font-style: italic;
    margin-top: 1rem;
    width: 60%;
    margin: 0 auto;
    margin-top: 3rem; }
  .login-information-content .register-button {
    font-size: 20px;
    margin: 3rem 0 1rem 0;
    padding: 4px 5rem;
    border-radius: 0; }

.login-howto {
  width: 100%;
  height: auto;
  background: url(/images/login-howto-background.png) no-repeat center center;
  color: #63af18;
  padding-bottom: 3rem; }
  .login-howto .title {
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    margin: 3rem 0; }
  .login-howto .item {
    margin-top: 2rem; }
  .login-howto img {
    width: 35%;
    height: auto; }
  .login-howto .icon-text {
    font-size: 24px;
    margin-top: 1rem; }
  .login-howto .information-button {
    font-size: 20px;
    margin: 3rem 0 1rem 0;
    padding: 4px 5rem;
    border-radius: 0; }

.login-footer {
  background: black;
  width: 100%;
  height: auto;
  color: white;
  padding: 1rem 0; }
  .login-footer .menu-separator {
    border-right: 1px solid;
    margin: 0 1rem;
    min-height: 30px; }
  .login-footer a {
    color: white;
    text-decoration: none; }
  .login-footer .text {
    font-size: 9px;
    text-align: center;
    margin: 0 auto;
    margin-top: 2rem;
    width: 70%; }
  .login-footer .menu-mobile {
    width: 55%;
    margin: 0 auto;
    font-size: 10px;
    margin-bottom: 1rem;
    margin-top: 2rem; }
  .login-footer .social-icons a {
    margin: 0 1rem; }

@media (max-width: 768px) {
  .login-header {
    flex-direction: column; }
    .login-header img {
      width: 120px;
      height: auto;
      margin-left: 1rem; }
  .login-form {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0; }
    .login-form .login-input {
      width: 90%;
      margin: 0 auto;
      margin: 3px 0; }
    .login-form .login-button {
      width: 50%;
      margin: 1rem auto; }
  .login-information-content .title {
    font-size: 35px; }
  .login-information-content .subtitle {
    font-size: 20px; }
  .login-information-content .information {
    width: 90%;
    font-size: 16px; }
  .login-howto .item {
    margin-top: 4rem; }
  .login-howto img {
    width: 20%;
    height: auto; }
  .login-howto .icon-text {
    font-size: 16px; }
  .login-footer .text {
    font-size: 8px;
    width: 90%; } }
